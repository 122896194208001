import './App.css';
import image from './header-image.png'; // Update with the correct path
import mobileImage from './header-image-mobile.png'; // Update with the correct path

const ThankYouComponent = ({ name, frequency, amount, onClose }) => {
    const formatAmount = (amount) => {
        return amount.toLocaleString();
    };

    return (
        <div className="thank-you-message">
            <div className="row row-1">
            <img src={image} alt="Combined Image" className="full-width-image" />

          </div>
          <div className="row row-1-mobile">
            <img src={mobileImage} alt="Combined Image" className="mobile-width-image" />
          </div>
            <div className="thank-you-content">
                {name}, baie dankie vir jou aankoop. Jy sal jou kaartjies per e-pos ontvang.
            </div>
            <div>
            <button onClick={onClose}>Maak toe</button>
            </div>
        </div>
    );
};

export default ThankYouComponent;
