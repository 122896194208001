import React, { useState, useEffect } from 'react';  // Added useEffect import
import { PaystackButton } from 'react-paystack';
import logo from './logo.png'; // Update with the correct path
import paystackLogos from './paystack-logos.png'; // Update with the correct path
import PolicyModal from './PolicyModal';
import './PaymentForm.css';



const PaymentForm = ({ name, amount, frequency, setName, setAmount, setFrequency, setPaymentStatus, setSoldOut }) => {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const serverURI = process.env.REACT_APP_SERVER_URI;
  const memberAmount = process.env.REACT_APP_MEMBER_AMOUNT;
  const nonMemberAmount = process.env.REACT_APP_NON_MEMBER_AMOUNT;
  const newMemberForm = process.env.REACT_APP_CRM_NEW_MEMBER_FORM;
  
  const [lastname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [cell, setCell] = useState('');
  const [isEmailValid, setEmailValid] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isMemberVerified, setIsMemberVerified] = useState(false);
  const [isMemberInfoUpdated, setIsMemberInfoUpdated] = useState(false);
  const [memberMessage, setMemberMessage] = useState('');
  const [updateURL, setUpdateURL] = useState('');
  const [isScrolling, setIsScrolling] = useState(false);
   const [memberError, setMemberError] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');
    const lastname = params.get('lastname');
    const email = params.get('email');
    const cell = params.get('cell');
    if (email && cell) {
      setName(name || '');
      setSurname(lastname || '');
      setEmail(email);
      setCell(cell);
      setIsMemberVerified(true);
      setIsMemberInfoUpdated(true);
      setFrequency('Ek is \'n StudentePlein lid'); // Set default frequency
      setAmount(memberAmount*100);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [setName, setSurname, setEmail, setCell, setFrequency]);
  const callback_url = "https://studenteplein.co.za"

  const isFormValid = isMemberVerified || (name !== '' && lastname !== '' && email !== '' && isEmailValid);

  const openPolicyModal = () => {
    setIsPolicyModalOpen(true);
  };

  const closePolicyModal = () => {
    setIsPolicyModalOpen(false);
  };

  const incrementSales = () => {


  };


  const handleKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      event.preventDefault(); // Prevent the key press
    }
  };

  const validateEmail = (inputEmail) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(inputEmail).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (validateEmail(value)) {
      setEmailValid(true);
      setEmailError('');  // Clear error message if the email is valid
    } else {
      setEmailError('Voer asseblief \'n geldige e-posadres in.');  // Set error message if the email is invalid
      setEmailValid(false);
    }
  };

  
  const handleFrequencySelect = (frequency) => {
    if (frequency === 'Ek wil lid word en afslag kry') {
      window.location.href = newMemberForm;
    } else {
      setFrequency(frequency);
      setMemberMessage();
    }
  };


  const getPaymentChannels = () => {
    return ["qr", "card", "eft"];
  };

  const componentProps = { 
    publicKey,
    amount: amount,
    email,
    firstname: name,
    lastname,
    currency: "ZAR",
    metadata: {
      terms: "yes",
      paymentType: "event_ticket",
      phone: cell,
      isMember: isMemberVerified
    },
    text: `Gaan voort na betaling: R${amount/100}`  ,
    callback_url,
    onSuccess: (transaction) => {
      setPaymentStatus('success');
    },
    channels: getPaymentChannels() // Set channels based on frequency
  }

  const verifyMembership = async () => {
    try {
      const response = await fetch(`${serverURI}/get/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: { email: email, phone: cell } })
      });

      const data = await response.json();
      if (data.member) {
        setIsMemberVerified(true);
        setMemberMessage('Ons het jou op ons databasis opgespoor...');
        setUpdateURL(data.updateURL);
        window.location.href = data.updateURL;
      } else {
        setIsMemberVerified(false);
        setMemberMessage('Ons kan jou nie op ons databasis vind nie. Maak seker dat jou besonderhede hierbo korrek is, of klik op die \'Ek wil lid word..\' opsie.');
      }
    } catch (error) {
      console.error('Error while verifying membership:', error);
      setMemberMessage('Fout tydens die verifikasie proses. Probeer asseblief weer.');
    }
  };

  return (
    <div className="donation-form">
      <img src={logo} alt="" className="logo-image" />
      <h1>Koop jou kaartjies</h1>
      {isMemberVerified && isMemberInfoUpdated ? (
        <>
          <p>Dankie dat jy lid is en inligting opgedateer het. Jy kan nou voortgaan om jou kaartjies te koop.</p>
          <PaystackButton
          {...componentProps}
          className={`next-btn ${!isFormValid ? 'disabled' : ''}`}
          onClick={(e) => {
            if (!isEmailValid || !isMemberInfoUpdated) {
              e.preventDefault();
              alert('Please ensure all information is updated and valid before proceeding.');
              return false;
            }
          }}
        />
        <div className="paystack-container">
            <img src={paystackLogos} alt="" className="paystack-logo" />
          </div>
      </>
      ) : (
        <>
          <h4>Lede kry afslag. As jy nog nie 'n lid is nie, word lid en kry afslag!</h4>
          <div className="policy-agreement">
      <label htmlFor="dedicate" className="dedicate-label">Deur kaartjies te koop stem jy in tot StudentePlein se</label>
      <span className="dedicate" onClick={openPolicyModal}>Gebruikersbeleid</span>.
    </div>
          <div className="frequency">
            {['Ek is reeds \'n StudentePlein lid', 'Ek wil lid word en afslag kry', 'Ek wil nie nou lid word nie, ek soek net kaartjies'].map(freq => (
              <button
                key={freq}
                className={`frequency-btn ${frequency === freq ? 'selected' : ''}`}
                onClick={() => handleFrequencySelect(freq)}
              >
                {freq}
              </button>
            ))}
          </div>
          <div className="options">
            {(frequency === 'Ek is reeds \'n StudentePlein lid' || frequency === 'Ek wil nie nou lid word nie, ek soek net kaartjies') && (
              <>
                <input type="text" required placeholder="Selfoonnommer" onChange={(e) => setCell(e.target.value)} />
                <input
                  type="email"
                  placeholder="E-posadres"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                {emailError && <div className="error-message">{emailError}</div>}
              </>
            )}
            {frequency === 'Ek wil nie nou lid word nie, ek soek net kaartjies' && (
              <>
                <input type="text" required placeholder="Naam" onChange={(e) => setName(e.target.value)} />
                <input type="text" required placeholder="Van" onChange={(e) => setSurname(e.target.value)} />
    
                <PaystackButton
          {...componentProps}

          className={`next-btn ${!isFormValid ? 'disabled' : ''}`}
          onClick={(e) => {
            if (!isEmailValid || !isMemberInfoUpdated) {
              e.preventDefault();
              alert('Please ensure all information is updated and valid before proceeding.');
              return false;
            }
          }}
        />
              </>
              
            )}
          </div>
          {frequency === 'Ek is reeds \'n StudentePlein lid' && (
              <button className='btn' onClick={verifyMembership}>
              Verifieer lidmaatskap
            </button>
            )}
        
          {memberMessage && <div className="error-message">{memberMessage}</div>}
         
          <div className="paystack-container">
            <img src={paystackLogos} alt="" className="paystack-logo" />
          </div>
          {/* Render the PolicyModal component if isPolicyModalOpen is true */}
          {isPolicyModalOpen && <PolicyModal onClose={closePolicyModal} />}
        </>
      )}
    </div>
  );
};

export default PaymentForm;
