import React from 'react';
import './PolicyModal.css';

const PolicyModal = ({ onClose }) => {

  const handleOverlayClick = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };
  
  return (
  <div className="modal-overlay" onClick={handleOverlayClick}>
  <div className="modal-content">
  <h2>GEBRUIKERSBELEID</h2>
  <h3>1. KAARTJIEAANKOPE</h3>
  <p>1.1 StudentePlein behou die reg voor om enige kaartjie wat aangekoop word te kanselleer en die tersaaklike bedrag terug te betaal, ongeag die rede vir kansellasie.</p>
  <p>1.2 Indien die geleentheid waarvoor die kaartjie gekoop word gekanselleer word weens omstandighede buite StudentePlein se beheer, mag StudentePlein kaartjies kanselleer sonder terugbetaling.</p>
  <p>1.3 Die koper van 'n kaartjie vrywaar StudentePlein van aanspreeklikheid enige verliesse wat gely mag word weens die bywoning of nie-bywoning van die geleentheid, insluitend maar nie beperk tot beserings, saakbeskadiging, lewensverlies.</p>
  <p>1.4 Kaartjies mag nie herverkoop word nie.</p>
  <p>1.5 Geen koper van enige kaartjie is outomaties geregtig op terugbetaling in geval waar 'n koper nie die geleentheid waarvoor die kaartjie gekoop is, kan bywoon nie. Terugbetaling kan geskied op grond van die uitsluitlike diskresie van StudentePlein.</p>
  <h3>2. DATA EN PRIVAATHEID</h3>
  <p>2.1 Terminologie in hierdie afdeling stem, waar toepaslik, ooreen met terminologie en die toepaslike definisies daarvoor in die Wet op Beskerming van Persoonlike Inligting, 4 van 2013 (“Wet”).</p>
  <p>2.2 "Paystack" verwys na Paystack South Africa (Edms) Bpk, ‘n derdepartydiensverskaffer wat betalings namens StudentePlein hanteer en ‘n PCI DSS Vlak 1 gesertifiseerde entiteit is. StudentePlein oefen geen beheer uit oor die wyse waarop Paystack Persoonlike Inligting van ‘n datasubjek verwerk en stoor nie, en aanvaar nie aanspreeklikheid daarvoor nie.</p>
<p>2.3 StudentePlein stoor ingevolge hierdie beleid hoofsaaklik twee kategorieë van Persoonlike Inligting:</p>
<ul>
  <li>2.3.1 Transaksie-inligting: Inligting wat ‘n datasubjek voorsien wanneer ‘n transaksie aangegaan word, soos aangevul deur inligting wat deur Paystack oor ‘n transaksie verskaf word.</li>
  <li>2.3.2 Registrasie-inligting: Inligting wat ‘n datasubjek voorsien wanneer 'n vorm ingevul word met persoonlike inligting soos naam, van en e-posadres.</li>
</ul>
<p>2.4 Die volgende Persoonlike Inligting word onder andere deur StudentePlein gestoor wanneer ‘n vorm op of via StudentePlein se kaartjieverkoopportaal ingevul word:</p>
<ul>
  <li>2.4.1 Naam en van</li>
  <li>2.4.2 E-posadres</li>
  <li>2.4.3 Selfoonnommer</li>
  <li>2.4.4 Verdere lidmaatskapinligting soos versoek wanneer 'n kaartjie gekoop word</li>
</ul>
<p>2.5 Persoonlike inligting word kragtens die voorskrifte van die Wet op Beskerming van Persoonlike Inligting, 4 van 2013, gestoor.</p>
<p>2.6 Deur ‘n vorm op StudentePlein se kaartjieportaal in te vul, stem ‘n datasubjek in tot die insameling van die inligting hierin vermeld.</p>
<p>2.7 ‘n Datasubjek gee toestemming dat StudentePlein inligting van ander betroubare bronne verkry om die inligting wat ‘n datasubjek verskaf het, of wat StudentePlein outomaties ingesamel het, op te dateer of aan te vul.</p>
<p>2.8 Behoudens die bepalings van hierdie beleid, sal StudentePlein nie ‘n datasubjek se persoonlike inligting met enige derde party deel nie, behalwe in omstandighede waar StudentePlein ingevolge ‘n hofbevel of die Wet verplig word om dit te doen.</p>
<p>2.9 Wanneer ondernemings namens StudentePlein dienste verrig word sodanige diensverskaffers wel met datasubjekte se persoonlike inligting voorsien maar verbied om die persoonlike inligting te gebruik vir ander doeleindes as wat deur StudentePlein versoek is en deur die Wet toegelaat word. ‘n Datasubjek wat ‘n skenking maak stem in dat sy of haar persoonlike inligting aan derdepartydiensverskaffers voorsien mag word om toepaslike funksies te verrig.</p>
<p>2.10 StudentePlein maak gebruik van kliëntebestuurstelsels om lede en nie-lede se inligting te verwerk. Wanneer 'n nie-lid 'n vorm op die kaartjieportaal invul, stem die gebruiker daartoe in dat StudentePlein hom of haar in die toekoms kontak oor lidmaatskap by StudentePlein.</p>
<p>2.11 StudentePlein het veiligheidsmaatreëls geïmplementeer wat ontwerp is om persoonlike inligting teen ongemagtigde toegang, openbaarmaking, gebruik en wysiging te beskerm.</p>
<p>2.12 StudentePlein bied geen waarborg dat enige sekerheidsmaatreëls ondeurdringbaar of onfeilbaar is nie, en word gevrywaar van aanspreeklikheid vir enige skade wat moontlik gely mag word deur ‘n onvoorsiene lek van inligting deur ‘n ongemagtigde party.</p>
<p>2.13 StudentePlein is by magte om, behoudens die bepalings oor kaartbesonderhede, enige persoonlike inligting wat kragtens hierdie beleid verkry word te stoor en te behou vir ‘n tydperk van ten minste ses jaar ná ‘n datasubjek se laaste kommunikasie met StudentePlein, insluitend ‘n laaste skenking, wat ook al laaste gebeur.</p>
<p>2.14 StudentePlein is voorts gemagtig om ‘n datasubjek se persoonlike inligting vir ‘n langer tydperk te behou as wat hierbo bepaal word, mits sodanige behoud vir historiese-, statistieke of navorsingsdoeleindes is.</p>
<p>2.15 Indien ‘n tydperk van ses jaar waarna hierbo verwys word verstryk het en ‘n rekord van persoonlike inligting nie vir historiese-, statistieke of navorsingsdoeleindes behou word nie, sal StudentePlein die tersaaklike rekord uitwis of de-identifiseer soos deur die Wet vereis word.</p>
<p>2.16 Die regte van ‘n datasubjek kragtens die beleid stem ooreen met die regte wat aan datasubjekte verleen word in die Wet. ‘n Datasubjek het, behoudens die omvang daarvan soos in die Wet bepaal, in besonder die reg:</p>
<ul>
  <li>2.16.1 om toegang tot eie persoonlike inligting te verkry;</li>
  <li>2.16.2 op regstelling van eie persoonlike inligting;</li>
  <li>2.16.3 om verwerking van eie persoonlike inligting te laat beperk;</li>
  <li>2.16.4 om beswaar te maak teen verwerking van eie persoonlike inligting;</li>
  <li>2.16.5 op data-oordraagbaarheid;</li>
  <li>2.16.6 om by 'n toesighoudende owerheid te kla; en</li>
  <li>2.16.7 om toestemmings soos in die Wet vereis en wat voorheen kragtens die Wet en/of hierdie of ‘n ander beleid van StudentePlein verleen is, te onttrek.</li>
</ul>
<p>2.17 ‘n Datasubjek kan sy of haar regte kragtens die beleid uitoefen deur ‘n versoek aan admin@studenteplein.co.za te stuur.</p>
<h3>3. DOMICILIUM CITANDI ET EXECUTANDI</h3>
<p>3.1 StudentePlein se domicilium citandi et executandi is Victoriastraat 11, Lutz-gebou, Stellenbosch, 7600.</p>
<p>3.2 Enige geskille ten opsigte van aangeleenthede wat deur hierdie beleid en verbandhoudende wetgewing gereguleer word, ontstaan vir jurisdiksionele doeleindes by StudentePlein se gekose domicilium citandi et executandi.</p>
<h3>4. KONTAKBESONDERHEDE</h3>
<p>4.1 Fisiese- en posadres: Victoriastraat 11, Lutz-gebou, Stellenbosch, 7600.</p>
<p>4.2 Webblad: <a href="https://studenteplein.co.za">studenteplein.co.za</a></p>
<p>4.3 E-posadres: <a href="mailto:admin@studenteplein.co.za">admin@studenteplein.co.za</a></p>
<p>4.4 Inligtingsbeampte:</p>
<ul>
  <li>4.4.1 Naam en van: Frederik Rudolph van Dyk</li>
  <li>4.4.2 E-pos: <a href="mailto:frederik@studenteplein.co.za">frederik@studenteplein.co.za</a></li>
</ul>
<button className="close-button" onClick={onClose}>Maak toe</button> {/* Button at the bottom or top as preferred */}
</div>
    </div>
  );
};

export default PolicyModal;
