import './App.css';
import image from './header-image.png'; // Update with the correct path
import mobileImage from './header-image-mobile.png'; // Update with the correct path

const SoldOutComponent = () => {
   
    return (
        <div className="event-card">
        <p className="tag">KAARTJIES IS ONGELUKKIG UITVERKOOP</p>
        <p className="secure-text">Sien jou volgende keer!</p>
      </div>
    );
};

export default SoldOutComponent;
