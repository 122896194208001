import React, { useState } from 'react';
import './App.css';
import PaymentForm from './PaymentForm';
import image from './header-image.png'; // Update with the correct path
import mobileImage from './header-image-mobile.png'; // Update with the correct path
import ThankYouComponent from './Succesfull';
import SoldOutComponent from './SoldOut';

function Parent() {
  const fromAmount = process.env.REACT_APP_FROM_AMOUNT;
  const memberAmount = process.env.REACT_APP_MEMBER_AMOUNT;
  const nonMemberAmount = process.env.REACT_APP_NON_MEMBER_AMOUNT;
  const eventName = process.env.REACT_APP_EVENT_NAME;
  const eventDate = process.env.REACT_APP_EVENT_DATE;
  const eventLocation = process.env.REACT_APP_EVENT_LOCATION;
  const eventDescription = process.env.REACT_APP_EVENT_DESCRIPTION;

  const [name, setName] = useState('');
  const [frequency, setFrequency] = useState('');
  const [amount, setAmount] = useState(nonMemberAmount * 100); // Default amount in cents
  const [paymentStatus, setPaymentStatus] = useState('');
  const [soldOut, setSoldOut] = useState(false); // Ensure it's boolean

  if (paymentStatus === 'success') {
    return <ThankYouComponent 
        name={name} 
        frequency={frequency} 
        amount={amount} 
        onClose={() => {
            setPaymentStatus('');
            window.location.href = "https://studenteplein.co.za";
        }}
    />;
  }

  return (
    <div className="app">
      <div className="row row-1">
        <img src={image} alt="Combined Image" className="full-width-image" />
      </div>
      <div className="row row-1-mobile">
        <img src={mobileImage} alt="Combined Image" className="mobile-width-image" />
      </div>
      <div className="row row-2">
        <div className="text-container-1">
          <h2>{eventName}</h2>
        </div>
        {!soldOut && (
          <div className="form-wrapper">
            <PaymentForm 
              name={name}
              amount={amount}
              frequency={frequency}
              setName={setName}
              setAmount={setAmount}
              setFrequency={setFrequency}
              setPaymentStatus={setPaymentStatus}
              setSoldOut={setSoldOut}
            />
          </div>
        )}
      </div>
      <div className="row row-3">
        <div className="text-container-2">
          {soldOut ? (
            <SoldOutComponent />
          ) : (
            <div className="event-card">
              <p className="tag">SLEGS PERSONE OUER AS 18</p>
              <h3>Vanaf {fromAmount}</h3>
              <p>Lede: R{memberAmount}</p>
              <p>Nie-lede: R{nonMemberAmount}</p>
              <div className="date-time">
                <h3>{eventDate}</h3>
              </div>
              <div className="location">
                <p>{eventLocation}</p>
              </div>
              <p className="secure-text">{eventDescription}</p>
            </div>
          )}
        </div>
      </div>
      <div className="footer-row">
        {/* Footer content here */}
      </div>
    </div>
  );
}

export default Parent;
